import React, { useState } from 'react';
import ima1 from '../image/IMG_4263.jpg';
import ima2 from '../image/IMG_4871.jpg';
import ima3 from '../image/IMG_4905.jpg';
import ima4 from '../image/IMA-NFT.avif';
import ima5 from '../image/maxresdefault.jpg';
import ima6 from '../image/IMG_4291.jpg';

const AllServices = () => {
  const serviceData = [
    {
      image: ima1,
      title: "i-CO₂ Carbon Capture",
      description: "Pioneering sustainability with our innovative carbon capture service: Employing cutting-edge technology to capture and securely store carbon emissions with utmost efficiency. Our approach significantly reduces environmental footprints, paving the way for a resilient future that champions climate change mitigation.",
    },
    {
      image: ima2,
      title: "Carbon Credit Sales by i-CO₂",
      description: "Committed to eco-conscious carbon offsetting solutions: We offer a comprehensive service that avails carbon credits for purchase to environmentally-minded individuals and businesses. With a primary focus on reducing carbon footprints and promoting environmental sustainability, we foster meaningful contributions to combatting climate change and transitioning to a greener economy.",
    },
    {
      image: ima3,
      title: "AI Crop Analysis for Carbon Capture",
      description: "Revolutionizing environmental solutions with AI: We leverage artificial intelligence in our advanced carbon capture technology, optimizing and streamlining the process of removing CO2 emissions from the atmosphere. By harnessing the capabilities of machine learning, we enhance efficiency and efficacy, thereby introducing a new era of eco-conscious technological innovations.",
    },
    {
      image: ima4,
      title: "EcoToken: NFT Carbon Credits by i-CO₂",
      description: "Tokenization of Sustainability with i-CO₂ NFT Carbon Credits: Pushing the boundaries of technological innovation and environmental responsibility, our NFT Carbon Credits offer a unique and advanced solution for carbon emission offsetting. By utilizing blockchain technology, each carbon credit is converted into a Non-Fungible Token (NFT), providing unparalleled transparency, traceability, and security. This service not only enables individuals and businesses to reduce and neutralize their carbon footprint, but it also promotes a decentralized and democratic carbon offset market. By acquiring our NFT Carbon Credits, you are contributing to the protection of the environment, the fight against climate change, and the fostering of a green and digital economy.",
    },
    {
      image: ima5,
      title: "Dashboards Data Analysis with i-CO₂",
      description: "Experience Advanced Data Insights with Dashboards Data Analysis by i-CO₂: Our data analysis service harnesses the power of data visualization dashboards to provide detailed insights into your carbon emission trends. These dashboards, powered by the latest data processing technologies, present complex carbon emission data in an accessible and user-friendly manner. Designed with the aim of empowering businesses and individuals alike, our dashboards offer real-time updates on key carbon emission metrics, providing a comprehensive overview of your carbon footprint. They enable users to closely monitor and understand their emission patterns, making it easier to implement effective strategies for carbon reduction. Through the integration of AI and machine learning, our dashboards are capable of analyzing vast amounts of data, enabling more precise forecasting and the identification of opportunities for improvement. Additionally, the data generated by these dashboards can be utilized to demonstrate compliance with environmental regulations and commitment to sustainability."
    },
    {
      image: ima6,
      title: "i-CO₂: Carbon Reduction Consulting",
      description: "Sustainability Consulting & Carbon Reduction Strategy with i-CO₂: Dive into a comprehensive consulting experience tailored to help your business become more sustainable and carbon-efficient. Our team of experienced environmental strategists will work closely with you to assess your current environmental impact and identify areas of improvement. Our consulting service is more than just advice - it's a partnership that aims at achieving measurable results. We combine data-driven insights with practical steps to help you reduce your carbon footprint and implement sustainable practices within your organization. Whether you are a start-up or an established business, our customized solutions will guide you towards a greener future. With i-CO₂, you can confidently transition towards sustainable operations, enhance your brand's reputation, and contribute positively to the fight against climate change.",
    },
  ];

  const ServiceCard = ({ data }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
      setIsExpanded(!isExpanded);
    };

    const description = isExpanded ? data.description : `${data.description.slice(0, 100)}...`;

    return (
      <div style={styles.cardContainer}>
        <img src={data.image} alt={data.title} style={styles.cardImage} />
        <h2 style={styles.cardTitle}>{data.title}</h2>
        <p style={styles.cardDescription}>{description}</p>
        <button onClick={handleExpandClick} style={styles.readMoreButton}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </div>
    );
  };

  const styles = {
    servicesContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'auto',
      padding: '10px',
      margin: '0 auto',
      marginTop: '60px',
      maxWidth: '1200px',
      
    },
    servicesTitle: {
      fontSize: '2.5em',
      marginBottom: '10px',
      textAlign: 'center',
      color: '#333',
    },
    cardsContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '10px',
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      padding: '20px',
      backgroundColor: '#fff',
      maxWidth: '300px',
    },
    cardImage: {
      width: '100%',
      height: '150px',  // Ajusta este valor según tus necesidades
      objectFit: 'cover',
    },
    cardTitle: {
      margin: '12px 0',
      color: '#333',
      fontSize: '1em',
    },
    cardDescription: {
      textAlign: 'center',
      color: '#666',
      fontSize: '1em',
    },
    readMoreButton: {
      backgroundColor: '#008000',
      color: '#FFF',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 15px',
      cursor: 'pointer',
      marginTop: '10px',
    },
  };

  return (
    <div style={styles.servicesContainer}>
      <h1 style={styles.servicesTitle}>Discover Our Services</h1>
      <div style={styles.cardsContainer}>
        {serviceData.map((service, index) => (
          <ServiceCard key={index} data={service} />
        ))}
      </div>
    </div>
  );
};

export default AllServices;
